:root {
  --main-text-color: #212529;
  --light-silver: #868e96;
  --serif: "IowanOldStyle-Roman", "Iowan", serif;
  --sans-serif: "avenir next", avenir, "helvetica neue", ubuntu, roboto, noto, "segoe ui", arial, sans-serif;
  --transition-values: all .4s cubic-bezier(.215, .61, .355, 1);
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: sans-serif;
  line-height: 1.15;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

progress {
  vertical-align: baseline;
}

[hidden], template {
  display: none;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
}

a:active, a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background-color: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

button, input, optgroup, select, textarea {
  font: inherit;
  margin: 0;
}

optgroup {
  font-weight: 700;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[type="reset"], [type="submit"], button, html [type="button"] {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring, button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.border-box, article, body, code, dd, div, dl, dt, fieldset, footer, form, header, html, input[type="email"], input[type="number"], input[type="password"], input[type="tel"], input[type="text"], input[type="url"], li, main, ol, p, pre, section, textarea, ul {
  box-sizing: border-box;
}

.cover {
  background-size: cover;
}

.contain {
  background-size: contain;
}

.ba {
  border-style: solid;
  border-width: 1px;
}

.bt {
  border-top-style: solid;
  border-top-width: 1px;
}

.br {
  border-right-style: solid;
  border-right-width: 1px;
}

.bb {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.bl {
  border-left-style: solid;
  border-left-width: 1px;
}

.bn {
  border-style: none;
  border-width: 0;
}

.b--black {
  border-color: #000;
}

.b--near-black {
  border-color: #111;
}

.b--dark-gray {
  border-color: #333;
}

.b--mid-gray {
  border-color: #555;
}

.b--gray {
  border-color: #777;
}

.b--silver {
  border-color: #999;
}

.b--light-silver {
  border-color: #aaa;
}

.b--light-gray {
  border-color: #eee;
}

.b--near-white {
  border-color: #f4f4f4;
}

.b--white {
  border-color: #fff;
}

.b--white-90 {
  border-color: #ffffffe6;
}

.b--white-80 {
  border-color: #fffc;
}

.b--white-70 {
  border-color: #ffffffb3;
}

.b--white-60 {
  border-color: #fff9;
}

.b--white-50 {
  border-color: #ffffff80;
}

.b--white-40 {
  border-color: #fff6;
}

.b--white-30 {
  border-color: #ffffff4d;
}

.b--white-20 {
  border-color: #fff3;
}

.b--white-10 {
  border-color: #ffffff1a;
}

.b--white-05 {
  border-color: #ffffff0d;
}

.b--white-025 {
  border-color: #ffffff06;
}

.b--white-0125 {
  border-color: #ffffff03;
}

.b--black-90 {
  border-color: #000000e6;
}

.b--black-80 {
  border-color: #000c;
}

.b--black-70 {
  border-color: #000000b3;
}

.b--black-60 {
  border-color: #0009;
}

.b--black-50 {
  border-color: #00000080;
}

.b--black-40 {
  border-color: #0006;
}

.b--black-30 {
  border-color: #0000004d;
}

.b--black-20 {
  border-color: #0003;
}

.b--black-10 {
  border-color: #0000001a;
}

.b--black-05 {
  border-color: #0000000d;
}

.b--black-025 {
  border-color: #00000006;
}

.b--black-0125 {
  border-color: #00000003;
}

.b--dark-red {
  border-color: #f00008;
}

.b--red {
  border-color: #ff3223;
}

.b--orange {
  border-color: #f3a801;
}

.b--gold {
  border-color: #f2c800;
}

.b--yellow {
  border-color: #ffde37;
}

.b--purple {
  border-color: #7d5da9;
}

.b--light-purple {
  border-color: #8d4f92;
}

.b--hot-pink {
  border-color: #d62288;
}

.b--dark-pink {
  border-color: #c64774;
}

.b--pink {
  border-color: #f49cc8;
}

.b--dark-green {
  border-color: #006c71;
}

.b--green {
  border-color: #41d69f;
}

.b--navy {
  border-color: #001b44;
}

.b--dark-blue {
  border-color: #00449e;
}

.b--blue {
  border-color: #357edd;
}

.b--light-blue {
  border-color: #96ccff;
}

.b--lightest-blue {
  border-color: #cdecff;
}

.b--washed-blue {
  border-color: #f6fffe;
}

.b--washed-green {
  border-color: #e8fdf5;
}

.b--washed-yellow {
  border-color: #fff8d5;
}

.b--light-pink {
  border-color: #efa4b8;
}

.b--light-yellow {
  border-color: #f3dd70;
}

.b--light-red {
  border-color: #ffd3c0;
}

.b--transparent {
  border-color: #0000;
}

.br0 {
  border-radius: 0;
}

.br1 {
  border-radius: .125rem;
}

.br2 {
  border-radius: .25rem;
}

.br3 {
  border-radius: .5rem;
}

.br4 {
  border-radius: 1rem;
}

.br-100 {
  border-radius: 100%;
}

.br--bottom {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.br--top {
  border-bottom-right-radius: 0;
}

.br--right, .br--top {
  border-bottom-left-radius: 0;
}

.br--right {
  border-top-left-radius: 0;
}

.br--left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.b--dotted {
  border-style: dotted;
}

.b--dashed {
  border-style: dashed;
}

.b--solid {
  border-style: solid;
}

.b--none {
  border-style: none;
}

.bw0 {
  border-width: 0;
}

.bw1 {
  border-width: .125rem;
}

.bw2 {
  border-width: .25rem;
}

.bw3 {
  border-width: .5rem;
}

.bw4 {
  border-width: 1rem;
}

.bw5 {
  border-width: 2rem;
}

.bt-0 {
  border-top-width: 0;
}

.br-0 {
  border-right-width: 0;
}

.bb-0 {
  border-bottom-width: 0;
}

.bl-0 {
  border-left-width: 0;
}

.shadow-1 {
  box-shadow: 0 0 4px 2px #0003;
}

.shadow-2 {
  box-shadow: 0 0 8px 2px #0003;
}

.shadow-3 {
  box-shadow: 2px 2px 4px 2px #0003;
}

.shadow-4 {
  box-shadow: 2px 2px 8px #0003;
}

.shadow-5 {
  box-shadow: 4px 4px 8px #0003;
}

.pre {
  overflow: scroll;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.top-1 {
  top: 1rem;
}

.right-1 {
  right: 1rem;
}

.bottom-1 {
  bottom: 1rem;
}

.left-1 {
  left: 1rem;
}

.top-2 {
  top: 2rem;
}

.right-2 {
  right: 2rem;
}

.bottom-2 {
  bottom: 2rem;
}

.left-2 {
  left: 2rem;
}

.top--1 {
  top: -1rem;
}

.right--1 {
  right: -1rem;
}

.bottom--1 {
  bottom: -1rem;
}

.left--1 {
  left: -1rem;
}

.top--2 {
  top: -2rem;
}

.right--2 {
  right: -2rem;
}

.bottom--2 {
  bottom: -2rem;
}

.left--2 {
  left: -2rem;
}

.absolute--fill {
  inset: 0;
}

.cf:after, .cf:before {
  content: " ";
  display: table;
}

.cf:after {
  clear: both;
}

.cf {
  zoom: 1;
}

.cl {
  clear: left;
}

.cr {
  clear: right;
}

.cb {
  clear: both;
}

.cn {
  clear: none;
}

.dn {
  display: none;
}

.di {
  display: inline;
}

.db {
  display: block;
}

.dib {
  display: inline-block;
}

.dit {
  display: inline-table;
}

.dt {
  display: table;
}

.dtc {
  display: table-cell;
}

.dt-row {
  display: table-row;
}

.dt-row-group {
  display: table-row-group;
}

.dt-column {
  display: table-column;
}

.dt-column-group {
  display: table-column-group;
}

.dt--fixed {
  table-layout: fixed;
  width: 100%;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-auto {
  min-width: 0;
  min-height: 0;
  flex: auto;
}

.flex-none {
  flex: none;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-baseline {
  align-self: baseline;
}

.self-stretch {
  align-self: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.content-start {
  align-content: flex-start;
}

.content-end {
  align-content: flex-end;
}

.content-center {
  align-content: center;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

.content-stretch {
  align-content: stretch;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-last {
  order: 99999;
}

.fl {
  float: left;
}

.fl, .fr {
  _display: inline;
}

.fr {
  float: right;
}

.fn {
  float: none;
}

.sans-serif {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica, helvetica neue, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
}

.serif {
  font-family: georgia, times, serif;
}

.system-sans-serif {
  font-family: sans-serif;
}

.system-serif {
  font-family: serif;
}

.code, code {
  font-family: Consolas, monaco, monospace;
}

.courier {
  font-family: Courier Next, courier, monospace;
}

.helvetica {
  font-family: helvetica neue, helvetica, sans-serif;
}

.avenir {
  font-family: avenir next, avenir, sans-serif;
}

.georgia {
  font-family: georgia, serif;
}

.times {
  font-family: times, serif;
}

.bodoni {
  font-family: Bodoni MT, serif;
}

.calisto {
  font-family: Calisto MT, serif;
}

.garamond {
  font-family: garamond, serif;
}

.baskerville {
  font-family: baskerville, serif;
}

.i {
  font-style: italic;
}

.fs-normal {
  font-style: normal;
}

.normal {
  font-weight: 400;
}

.b {
  font-weight: 700;
}

.fw1 {
  font-weight: 100;
}

.fw2 {
  font-weight: 200;
}

.fw3 {
  font-weight: 300;
}

.fw4 {
  font-weight: 400;
}

.fw5 {
  font-weight: 500;
}

.fw6 {
  font-weight: 600;
}

.fw7 {
  font-weight: 700;
}

.fw8 {
  font-weight: 800;
}

.fw9 {
  font-weight: 900;
}

.input-reset {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.h1 {
  height: 1rem;
}

.h2 {
  height: 2rem;
}

.h3 {
  height: 4rem;
}

.h4 {
  height: 8rem;
}

.h5 {
  height: 16rem;
}

.h-25 {
  height: 25%;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

.h-inherit {
  height: inherit;
}

.tracked {
  letter-spacing: .1em;
}

.tracked-tight {
  letter-spacing: -.05em;
}

.tracked-mega {
  letter-spacing: .25em;
}

.lh-solid {
  line-height: 1;
}

.lh-title {
  line-height: 1.3;
}

.lh-copy {
  line-height: 1.6;
}

.link {
  text-decoration: none;
}

.link, .link:active, .link:focus, .link:hover, .link:link, .link:visited {
  transition: color .15s ease-in;
}

.list {
  list-style-type: none;
}

.mw-100 {
  max-width: 100%;
}

.mw1 {
  max-width: 1rem;
}

.mw2 {
  max-width: 2rem;
}

.mw3 {
  max-width: 4rem;
}

.mw4 {
  max-width: 8rem;
}

.mw5 {
  max-width: 16rem;
}

.mw6 {
  max-width: 32rem;
}

.mw7 {
  max-width: 48rem;
}

.mw8 {
  max-width: 64rem;
}

.mw9 {
  max-width: 96rem;
}

.mw-none {
  max-width: none;
}

.w1 {
  width: 1rem;
}

.w2 {
  width: 2rem;
}

.w3 {
  width: 4rem;
}

.w4 {
  width: 8rem;
}

.w5 {
  width: 16rem;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-33 {
  width: 33%;
}

.w-34 {
  width: 34%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-75 {
  width: 75%;
}

.w-80 {
  width: 80%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.overflow-visible {
  overflow: visible;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-auto {
  overflow: auto;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.static {
  position: static;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.o-100 {
  opacity: 1;
}

.o-90 {
  opacity: .9;
}

.o-80 {
  opacity: .8;
}

.o-70 {
  opacity: .7;
}

.o-60 {
  opacity: .6;
}

.o-50 {
  opacity: .5;
}

.o-40 {
  opacity: .4;
}

.o-30 {
  opacity: .3;
}

.o-20 {
  opacity: .2;
}

.o-10 {
  opacity: .1;
}

.o-05 {
  opacity: .05;
}

.o-025 {
  opacity: .025;
}

.o-0 {
  opacity: 0;
}

.black-90 {
  color: #000000e6;
}

.black-80 {
  color: #000c;
}

.black-70 {
  color: #000000b3;
}

.black-60 {
  color: #0009;
}

.black-50 {
  color: #00000080;
}

.black-40 {
  color: #0006;
}

.black-30 {
  color: #0000004d;
}

.black-20 {
  color: #0003;
}

.black-10 {
  color: #0000001a;
}

.black-05 {
  color: #0000000d;
}

.white-90 {
  color: #ffffffe6;
}

.white-80 {
  color: #fffc;
}

.white-70 {
  color: #ffffffb3;
}

.white-60 {
  color: #fff9;
}

.white-50 {
  color: #ffffff80;
}

.white-40 {
  color: #fff6;
}

.white-30 {
  color: #ffffff4d;
}

.white-20 {
  color: #fff3;
}

.white-10 {
  color: #ffffff1a;
}

.black {
  color: #000;
}

.near-black {
  color: #111;
}

.dark-gray {
  color: #333;
}

.mid-gray {
  color: #555;
}

.gray {
  color: #777;
}

.silver {
  color: #999;
}

.light-silver {
  color: #aaa;
}

.moon-gray {
  color: #ccc;
}

.light-gray {
  color: #eee;
}

.near-white {
  color: #f4f4f4;
}

.white {
  color: #fff;
}

.dark-red {
  color: #f00008;
}

.red {
  color: #ff3223;
}

.orange {
  color: #f3a801;
}

.gold {
  color: #f2c800;
}

.yellow {
  color: #ffde37;
}

.purple {
  color: #7d5da9;
}

.light-purple {
  color: #8d4f92;
}

.hot-pink {
  color: #d62288;
}

.dark-pink {
  color: #c64774;
}

.pink {
  color: #f49cc8;
}

.dark-green {
  color: #006c71;
}

.green {
  color: #41d69f;
}

.navy {
  color: #001b44;
}

.dark-blue {
  color: #00449e;
}

.blue {
  color: #357edd;
}

.light-blue {
  color: #96ccff;
}

.lightest-blue {
  color: #cdecff;
}

.washed-blue {
  color: #f6fffe;
}

.washed-green {
  color: #e8fdf5;
}

.washed-yellow {
  color: #fff8d5;
}

.light-pink {
  color: #efa4b8;
}

.light-yellow {
  color: #f3dd70;
}

.light-red {
  color: #ffd3c0;
}

.bg-black-90 {
  background-color: #000000e6;
}

.bg-black-80 {
  background-color: #000c;
}

.bg-black-70 {
  background-color: #000000b3;
}

.bg-black-60 {
  background-color: #0009;
}

.bg-black-50 {
  background-color: #00000080;
}

.bg-black-40 {
  background-color: #0006;
}

.bg-black-30 {
  background-color: #0000004d;
}

.bg-black-20 {
  background-color: #0003;
}

.bg-black-10 {
  background-color: #0000001a;
}

.bg-black-05 {
  background-color: #0000000d;
}

.bg-white-90 {
  background-color: #ffffffe6;
}

.bg-white-80 {
  background-color: #fffc;
}

.bg-white-70 {
  background-color: #ffffffb3;
}

.bg-white-60 {
  background-color: #fff9;
}

.bg-white-50 {
  background-color: #ffffff80;
}

.bg-white-40 {
  background-color: #fff6;
}

.bg-white-30 {
  background-color: #ffffff4d;
}

.bg-white-20 {
  background-color: #fff3;
}

.bg-white-10 {
  background-color: #ffffff1a;
}

.bg-black {
  background-color: #000;
}

.bg-near-black {
  background-color: #111;
}

.bg-dark-gray {
  background-color: #333;
}

.bg-mid-gray {
  background-color: #555;
}

.bg-gray {
  background-color: #777;
}

.bg-silver {
  background-color: #999;
}

.bg-light-silver {
  background-color: #aaa;
}

.bg-moon-gray {
  background-color: #ccc;
}

.bg-light-gray {
  background-color: #eee;
}

.bg-near-white {
  background-color: #f4f4f4;
}

.bg-white {
  background-color: #fff;
}

.bg-transparent {
  background-color: #0000;
}

.bg-dark-red {
  background-color: #f00008;
}

.bg-red {
  background-color: #ff3223;
}

.bg-orange {
  background-color: #f3a801;
}

.bg-gold {
  background-color: #f2c800;
}

.bg-yellow {
  background-color: #ffde37;
}

.bg-purple {
  background-color: #7d5da9;
}

.bg-light-purple {
  background-color: #8d4f92;
}

.bg-hot-pink {
  background-color: #d62288;
}

.bg-dark-pink {
  background-color: #c64774;
}

.bg-pink {
  background-color: #f49cc8;
}

.bg-dark-green {
  background-color: #006c71;
}

.bg-green {
  background-color: #41d69f;
}

.bg-navy {
  background-color: #001b44;
}

.bg-dark-blue {
  background-color: #00449e;
}

.bg-blue {
  background-color: #357edd;
}

.bg-light-blue {
  background-color: #96ccff;
}

.bg-lightest-blue {
  background-color: #cdecff;
}

.bg-washed-blue {
  background-color: #f6fffe;
}

.bg-washed-green {
  background-color: #e8fdf5;
}

.bg-washed-yellow {
  background-color: #fff8d5;
}

.bg-light-pink {
  background-color: #efa4b8;
}

.bg-light-yellow {
  background-color: #f3dd70;
}

.bg-light-red {
  background-color: #ffd3c0;
}

.hover-black:hover {
  color: #000;
}

.hover-near-black:hover {
  color: #111;
}

.hover-dark-gray:hover {
  color: #333;
}

.hover-mid-gray:hover {
  color: #555;
}

.hover-gray:hover {
  color: #777;
}

.hover-silver:hover {
  color: #999;
}

.hover-light-silver:hover {
  color: #aaa;
}

.hover-moon-gray:hover {
  color: #ccc;
}

.hover-light-gray:hover {
  color: #eee;
}

.hover-near-white:hover {
  color: #f4f4f4;
}

.hover-white:hover {
  color: #fff;
}

.hover-bg-black:hover {
  background-color: #000;
}

.hover-bg-near-black:hover {
  background-color: #111;
}

.hover-bg-dark-gray:hover {
  background-color: #333;
}

.hover-bg-mid-gray:hover {
  background-color: #555;
}

.hover-bg-gray:hover {
  background-color: #777;
}

.hover-bg-silver:hover {
  background-color: #999;
}

.hover-bg-light-silver:hover {
  background-color: #aaa;
}

.hover-bg-moon-gray:hover {
  background-color: #ccc;
}

.hover-bg-light-gray:hover {
  background-color: #eee;
}

.hover-bg-near-white:hover {
  background-color: #f4f4f4;
}

.hover-bg-white:hover {
  background-color: #fff;
}

.hover-bg-transparent:hover {
  background-color: #0000;
}

.hover-bg-dark-red:hover {
  background-color: #f00008;
}

.hover-bg-red:hover {
  background-color: #ff3223;
}

.hover-bg-orange:hover {
  background-color: #f3a801;
}

.hover-bg-gold:hover {
  background-color: #f2c800;
}

.hover-bg-yellow:hover {
  background-color: #ffde37;
}

.hover-bg-purple:hover {
  background-color: #7d5da9;
}

.hover-bg-light-purple:hover {
  background-color: #8d4f92;
}

.hover-bg-hot-pink:hover {
  background-color: #d62288;
}

.hover-bg-dark-pink:hover {
  background-color: #c64774;
}

.hover-bg-pink:hover {
  background-color: #f49cc8;
}

.hover-bg-dark-green:hover {
  background-color: #006c71;
}

.hover-bg-green:hover {
  background-color: #41d69f;
}

.hover-bg-navy:hover {
  background-color: #001b44;
}

.hover-bg-dark-blue:hover {
  background-color: #00449e;
}

.hover-bg-blue:hover {
  background-color: #357edd;
}

.hover-bg-light-blue:hover {
  background-color: #96ccff;
}

.hover-bg-lightest-blue:hover {
  background-color: #cdecff;
}

.hover-bg-washed-blue:hover {
  background-color: #f6fffe;
}

.hover-bg-washed-green:hover {
  background-color: #e8fdf5;
}

.hover-bg-washed-yellow:hover {
  background-color: #fff8d5;
}

.hover-bg-light-pink:hover {
  background-color: #efa4b8;
}

.hover-bg-light-yellow:hover {
  background-color: #f3dd70;
}

.hover-bg-light-red:hover {
  background-color: #ffd3c0;
}

.pa0 {
  padding: 0;
}

.pa1 {
  padding: .25rem;
}

.pa2 {
  padding: .5rem;
}

.pa3 {
  padding: 1rem;
}

.pa4 {
  padding: 2rem;
}

.pa5 {
  padding: 4rem;
}

.pa6 {
  padding: 8rem;
}

.pa7 {
  padding: 16rem;
}

.pl0 {
  padding-left: 0;
}

.pl1 {
  padding-left: .25rem;
}

.pl2 {
  padding-left: .5rem;
}

.pl3 {
  padding-left: 1rem;
}

.pl4 {
  padding-left: 2rem;
}

.pl5 {
  padding-left: 4rem;
}

.pl6 {
  padding-left: 8rem;
}

.pl7 {
  padding-left: 16rem;
}

.pr0 {
  padding-right: 0;
}

.pr1 {
  padding-right: .25rem;
}

.pr2 {
  padding-right: .5rem;
}

.pr3 {
  padding-right: 1rem;
}

.pr4 {
  padding-right: 2rem;
}

.pr5 {
  padding-right: 4rem;
}

.pr6 {
  padding-right: 8rem;
}

.pr7 {
  padding-right: 16rem;
}

.pb0 {
  padding-bottom: 0;
}

.pb1 {
  padding-bottom: .25rem;
}

.pb2 {
  padding-bottom: .5rem;
}

.pb3 {
  padding-bottom: 1rem;
}

.pb4 {
  padding-bottom: 2rem;
}

.pb5 {
  padding-bottom: 4rem;
}

.pb6 {
  padding-bottom: 8rem;
}

.pb7 {
  padding-bottom: 16rem;
}

.pt0 {
  padding-top: 0;
}

.pt1 {
  padding-top: .25rem;
}

.pt2 {
  padding-top: .5rem;
}

.pt3 {
  padding-top: 1rem;
}

.pt4 {
  padding-top: 2rem;
}

.pt5 {
  padding-top: 4rem;
}

.pt6 {
  padding-top: 8rem;
}

.pt7 {
  padding-top: 16rem;
}

.pv0 {
  padding-top: 0;
  padding-bottom: 0;
}

.pv1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.pv2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pv3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pv4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pv5 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.pv6 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.pv7 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}

.ph0 {
  padding-left: 0;
  padding-right: 0;
}

.ph1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.ph2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.ph3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.ph4 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.ph5 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.ph6 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.ph7 {
  padding-left: 16rem;
  padding-right: 16rem;
}

.ma0 {
  margin: 0;
}

.ma1 {
  margin: .25rem;
}

.ma2 {
  margin: .5rem;
}

.ma3 {
  margin: 1rem;
}

.ma4 {
  margin: 2rem;
}

.ma5 {
  margin: 4rem;
}

.ma6 {
  margin: 8rem;
}

.ma7 {
  margin: 16rem;
}

.ml0 {
  margin-left: 0;
}

.ml1 {
  margin-left: .25rem;
}

.ml2 {
  margin-left: .5rem;
}

.ml3 {
  margin-left: 1rem;
}

.ml4 {
  margin-left: 2rem;
}

.ml5 {
  margin-left: 4rem;
}

.ml6 {
  margin-left: 8rem;
}

.ml7 {
  margin-left: 16rem;
}

.mr0 {
  margin-right: 0;
}

.mr1 {
  margin-right: .25rem;
}

.mr2 {
  margin-right: .5rem;
}

.mr3 {
  margin-right: 1rem;
}

.mr4 {
  margin-right: 2rem;
}

.mr5 {
  margin-right: 4rem;
}

.mr6 {
  margin-right: 8rem;
}

.mr7 {
  margin-right: 16rem;
}

.mb0 {
  margin-bottom: 0;
}

.mb1 {
  margin-bottom: .25rem;
}

.mb2 {
  margin-bottom: .5rem;
}

.mb3 {
  margin-bottom: 1rem;
}

.mb4 {
  margin-bottom: 2rem;
}

.mb5 {
  margin-bottom: 4rem;
}

.mb6 {
  margin-bottom: 8rem;
}

.mb7 {
  margin-bottom: 16rem;
}

.mt0 {
  margin-top: 0;
}

.mt1 {
  margin-top: .25rem;
}

.mt2 {
  margin-top: .5rem;
}

.mt3 {
  margin-top: 1rem;
}

.mt4 {
  margin-top: 2rem;
}

.mt5 {
  margin-top: 4rem;
}

.mt6 {
  margin-top: 8rem;
}

.mt7 {
  margin-top: 16rem;
}

.mv0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mv1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.mv2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mv3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mv4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mv5 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.mv6 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.mv7 {
  margin-top: 16rem;
  margin-bottom: 16rem;
}

.mh0 {
  margin-left: 0;
  margin-right: 0;
}

.mh1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mh2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mh3 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mh4 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mh5 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mh6 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.mh7 {
  margin-left: 16rem;
  margin-right: 16rem;
}

.collapse {
  border-collapse: collapse;
  border-spacing: 0;
}

.striped--moon-gray:nth-child(2n+1) {
  background-color: #ccc;
}

.striped--light-gray:nth-child(2n+1) {
  background-color: #eee;
}

.striped--near-white:nth-child(2n+1) {
  background-color: #f4f4f4;
}

.strike {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

.no-underline {
  text-decoration: none;
}

.tl {
  text-align: left;
}

.tr {
  text-align: right;
}

.tc {
  text-align: center;
}

.ttc {
  text-transform: capitalize;
}

.ttl {
  text-transform: lowercase;
}

.ttu {
  text-transform: uppercase;
}

.ttn {
  text-transform: none;
}

.f-6, .f-headline {
  font-size: 6rem;
}

.f-5, .f-subheadline {
  font-size: 5rem;
}

.f1 {
  font-size: 3rem;
}

.f2 {
  font-size: 2.25rem;
}

.f3 {
  font-size: 1.5rem;
}

.f4 {
  font-size: 1.25rem;
}

.f5 {
  font-size: 1rem;
}

.f6 {
  font-size: .875rem;
}

.measure {
  max-width: 30em;
}

.measure-wide {
  max-width: 34em;
}

.measure-narrow {
  max-width: 20em;
}

.indent {
  text-indent: 1em;
  margin-top: 0;
  margin-bottom: 0;
}

.small-caps {
  font-variant: small-caps;
}

.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.aspect-ratio {
  height: 0;
  position: relative;
}

.aspect-ratio--16x9 {
  padding-bottom: 56.25%;
}

.aspect-ratio--4x3 {
  padding-bottom: 75%;
}

.aspect-ratio--8x5 {
  padding-bottom: 62.5%;
}

.aspect-ratio--object {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  inset: 0;
}

.overflow-container {
  overflow-y: scroll;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.clip {
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  _position: absolute !important;
  position: fixed !important;
}

.ws-normal {
  white-space: normal;
}

.nowrap {
  white-space: nowrap;
}

.pre {
  white-space: pre;
}

.v-base {
  vertical-align: baseline;
}

.v-mid {
  vertical-align: middle;
}

.v-top {
  vertical-align: top;
}

.v-btm {
  vertical-align: bottom;
}

.dim {
  opacity: 1;
}

.dim, .dim:focus, .dim:hover {
  transition: opacity .15s ease-in;
}

.dim:focus, .dim:hover {
  opacity: .5;
}

.dim:active {
  opacity: .8;
  transition: opacity .15s ease-out;
}

.hide-child .child {
  opacity: 0;
  transition: opacity .15s ease-in;
}

.hide-child:active .child, .hide-child:focus .child, .hide-child:hover .child {
  opacity: 1;
  transition: opacity .15s ease-in;
}

.underline-hover:focus, .underline-hover:hover {
  text-decoration: underline;
}

.grow {
  transition: transform .2s;
}

.grow:focus, .grow:hover {
  transform: scale(1.05);
}

.grow-large {
  transition: transform .2s;
}

.grow-large:focus, .grow-large:hover {
  transform: scale(1.2);
}

.pointer:hover {
  cursor: pointer;
}

.shadow-hover:focus, .shadow-hover:hover {
  box-shadow: 0 0 8px 2px #0003;
}

.debug * {
  outline: 1px solid gold;
}

@media screen and (min-width: 30em) {
  .cover-ns {
    background-size: cover;
  }

  .contain-ns {
    background-size: contain;
  }

  .ba-ns {
    border-style: solid;
    border-width: 1px;
  }

  .bt-ns {
    border-top-style: solid;
    border-top-width: 1px;
  }

  .br-ns {
    border-right-style: solid;
    border-right-width: 1px;
  }

  .bb-ns {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

  .bl-ns {
    border-left-style: solid;
    border-left-width: 1px;
  }

  .bn-ns {
    border-style: none;
    border-width: 0;
  }

  .br0-ns {
    border-radius: 0;
  }

  .br1-ns {
    border-radius: .125rem;
  }

  .br2-ns {
    border-radius: .25rem;
  }

  .br3-ns {
    border-radius: .5rem;
  }

  .br4-ns {
    border-radius: 1rem;
  }

  .br-100-ns {
    border-radius: 100%;
  }

  .br--bottom-ns {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .br--top-ns {
    border-bottom-right-radius: 0;
  }

  .br--right-ns, .br--top-ns {
    border-bottom-left-radius: 0;
  }

  .br--right-ns {
    border-top-left-radius: 0;
  }

  .br--left-ns {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .b--dotted-ns {
    border-style: dotted;
  }

  .b--dashed-ns {
    border-style: dashed;
  }

  .b--solid-ns {
    border-style: solid;
  }

  .b--none-ns {
    border-style: none;
  }

  .bw0-ns {
    border-width: 0;
  }

  .bw1-ns {
    border-width: .125rem;
  }

  .bw2-ns {
    border-width: .25rem;
  }

  .bw3-ns {
    border-width: .5rem;
  }

  .bw4-ns {
    border-width: 1rem;
  }

  .bw5-ns {
    border-width: 2rem;
  }

  .bt-0-ns {
    border-top-width: 0;
  }

  .br-0-ns {
    border-right-width: 0;
  }

  .bb-0-ns {
    border-bottom-width: 0;
  }

  .bl-0-ns {
    border-left-width: 0;
  }

  .shadow-1-ns {
    box-shadow: 0 0 4px 2px #0003;
  }

  .shadow-2-ns {
    box-shadow: 0 0 8px 2px #0003;
  }

  .shadow-3-ns {
    box-shadow: 2px 2px 4px 2px #0003;
  }

  .shadow-4-ns {
    box-shadow: 2px 2px 8px #0003;
  }

  .shadow-5-ns {
    box-shadow: 4px 4px 8px #0003;
  }

  .top-0-ns {
    top: 0;
  }

  .left-0-ns {
    left: 0;
  }

  .right-0-ns {
    right: 0;
  }

  .bottom-0-ns {
    bottom: 0;
  }

  .top-1-ns {
    top: 1rem;
  }

  .left-1-ns {
    left: 1rem;
  }

  .right-1-ns {
    right: 1rem;
  }

  .bottom-1-ns {
    bottom: 1rem;
  }

  .top-2-ns {
    top: 2rem;
  }

  .left-2-ns {
    left: 2rem;
  }

  .right-2-ns {
    right: 2rem;
  }

  .bottom-2-ns {
    bottom: 2rem;
  }

  .top--1-ns {
    top: -1rem;
  }

  .right--1-ns {
    right: -1rem;
  }

  .bottom--1-ns {
    bottom: -1rem;
  }

  .left--1-ns {
    left: -1rem;
  }

  .top--2-ns {
    top: -2rem;
  }

  .right--2-ns {
    right: -2rem;
  }

  .bottom--2-ns {
    bottom: -2rem;
  }

  .left--2-ns {
    left: -2rem;
  }

  .absolute--fill-ns {
    inset: 0;
  }

  .cl-ns {
    clear: left;
  }

  .cr-ns {
    clear: right;
  }

  .cb-ns {
    clear: both;
  }

  .cn-ns {
    clear: none;
  }

  .dn-ns {
    display: none;
  }

  .di-ns {
    display: inline;
  }

  .db-ns {
    display: block;
  }

  .dib-ns {
    display: inline-block;
  }

  .dit-ns {
    display: inline-table;
  }

  .dt-ns {
    display: table;
  }

  .dtc-ns {
    display: table-cell;
  }

  .dt-row-ns {
    display: table-row;
  }

  .dt-row-group-ns {
    display: table-row-group;
  }

  .dt-column-ns {
    display: table-column;
  }

  .dt-column-group-ns {
    display: table-column-group;
  }

  .dt--fixed-ns {
    table-layout: fixed;
    width: 100%;
  }

  .flex-ns {
    display: flex;
  }

  .inline-flex-ns {
    display: inline-flex;
  }

  .flex-auto-ns {
    min-width: 0;
    min-height: 0;
    flex: auto;
  }

  .flex-none-ns {
    flex: none;
  }

  .flex-column-ns {
    flex-direction: column;
  }

  .flex-row-ns {
    flex-direction: row;
  }

  .flex-wrap-ns {
    flex-wrap: wrap;
  }

  .items-start-ns {
    align-items: flex-start;
  }

  .items-end-ns {
    align-items: flex-end;
  }

  .items-center-ns {
    align-items: center;
  }

  .items-baseline-ns {
    align-items: baseline;
  }

  .items-stretch-ns {
    align-items: stretch;
  }

  .self-start-ns {
    align-self: flex-start;
  }

  .self-end-ns {
    align-self: flex-end;
  }

  .self-center-ns {
    align-self: center;
  }

  .self-baseline-ns {
    align-self: baseline;
  }

  .self-stretch-ns {
    align-self: stretch;
  }

  .justify-start-ns {
    justify-content: flex-start;
  }

  .justify-end-ns {
    justify-content: flex-end;
  }

  .justify-center-ns {
    justify-content: center;
  }

  .justify-between-ns {
    justify-content: space-between;
  }

  .justify-around-ns {
    justify-content: space-around;
  }

  .content-start-ns {
    align-content: flex-start;
  }

  .content-end-ns {
    align-content: flex-end;
  }

  .content-center-ns {
    align-content: center;
  }

  .content-between-ns {
    align-content: space-between;
  }

  .content-around-ns {
    align-content: space-around;
  }

  .content-stretch-ns {
    align-content: stretch;
  }

  .order-0-ns {
    order: 0;
  }

  .order-1-ns {
    order: 1;
  }

  .order-2-ns {
    order: 2;
  }

  .order-3-ns {
    order: 3;
  }

  .order-4-ns {
    order: 4;
  }

  .order-5-ns {
    order: 5;
  }

  .order-6-ns {
    order: 6;
  }

  .order-7-ns {
    order: 7;
  }

  .order-8-ns {
    order: 8;
  }

  .order-last-ns {
    order: 99999;
  }

  .fl-ns {
    float: left;
  }

  .fl-ns, .fr-ns {
    display: inline;
  }

  .fr-ns {
    float: right;
  }

  .fn-ns {
    float: none;
  }

  .i-ns {
    font-style: italic;
  }

  .fs-normal-ns {
    font-style: normal;
  }

  .normal-ns {
    font-weight: 400;
  }

  .b-ns {
    font-weight: 700;
  }

  .fw1-ns {
    font-weight: 100;
  }

  .fw2-ns {
    font-weight: 200;
  }

  .fw3-ns {
    font-weight: 300;
  }

  .fw4-ns {
    font-weight: 400;
  }

  .fw5-ns {
    font-weight: 500;
  }

  .fw6-ns {
    font-weight: 600;
  }

  .fw7-ns {
    font-weight: 700;
  }

  .fw8-ns {
    font-weight: 800;
  }

  .fw9-ns {
    font-weight: 900;
  }

  .h1-ns {
    height: 1rem;
  }

  .h2-ns {
    height: 2rem;
  }

  .h3-ns {
    height: 4rem;
  }

  .h4-ns {
    height: 8rem;
  }

  .h5-ns {
    height: 16rem;
  }

  .h-25-ns {
    height: 25%;
  }

  .h-50-ns {
    height: 50%;
  }

  .h-75-ns {
    height: 75%;
  }

  .h-100-ns {
    height: 100%;
  }

  .h-auto-ns {
    height: auto;
  }

  .h-inherit-ns {
    height: inherit;
  }

  .tracked-ns {
    letter-spacing: .1em;
  }

  .tracked-tight-ns {
    letter-spacing: -.05em;
  }

  .tracked-mega-ns {
    letter-spacing: .25em;
  }

  .lh-solid-ns {
    line-height: 1;
  }

  .lh-title-ns {
    line-height: 1.3;
  }

  .lh-copy-ns {
    line-height: 1.6;
  }

  .mw-100-ns {
    max-width: 100%;
  }

  .mw1-ns {
    max-width: 1rem;
  }

  .mw2-ns {
    max-width: 2rem;
  }

  .mw3-ns {
    max-width: 4rem;
  }

  .mw4-ns {
    max-width: 8rem;
  }

  .mw5-ns {
    max-width: 16rem;
  }

  .mw6-ns {
    max-width: 32rem;
  }

  .mw7-ns {
    max-width: 48rem;
  }

  .mw8-ns {
    max-width: 64rem;
  }

  .mw9-ns {
    max-width: 96rem;
  }

  .mw-none-ns {
    max-width: none;
  }

  .w1-ns {
    width: 1rem;
  }

  .w2-ns {
    width: 2rem;
  }

  .w3-ns {
    width: 4rem;
  }

  .w4-ns {
    width: 8rem;
  }

  .w5-ns {
    width: 16rem;
  }

  .w-10-ns {
    width: 10%;
  }

  .w-20-ns {
    width: 20%;
  }

  .w-25-ns {
    width: 25%;
  }

  .w-33-ns {
    width: 33%;
  }

  .w-34-ns {
    width: 34%;
  }

  .w-40-ns {
    width: 40%;
  }

  .w-50-ns {
    width: 50%;
  }

  .w-60-ns {
    width: 60%;
  }

  .w-75-ns {
    width: 75%;
  }

  .w-80-ns {
    width: 80%;
  }

  .w-100-ns {
    width: 100%;
  }

  .w-auto-ns {
    width: auto;
  }

  .overflow-visible-ns {
    overflow: visible;
  }

  .overflow-hidden-ns {
    overflow: hidden;
  }

  .overflow-scroll-ns {
    overflow: scroll;
  }

  .overflow-auto-ns {
    overflow: auto;
  }

  .overflow-x-visible-ns {
    overflow-x: visible;
  }

  .overflow-x-hidden-ns {
    overflow-x: hidden;
  }

  .overflow-x-scroll-ns {
    overflow-x: scroll;
  }

  .overflow-x-auto-ns {
    overflow-x: auto;
  }

  .overflow-y-visible-ns {
    overflow-y: visible;
  }

  .overflow-y-hidden-ns {
    overflow-y: hidden;
  }

  .overflow-y-scroll-ns {
    overflow-y: scroll;
  }

  .overflow-y-auto-ns {
    overflow-y: auto;
  }

  .static-ns {
    position: static;
  }

  .relative-ns {
    position: relative;
  }

  .absolute-ns {
    position: absolute;
  }

  .fixed-ns {
    position: fixed;
  }

  .pa0-ns {
    padding: 0;
  }

  .pa1-ns {
    padding: .25rem;
  }

  .pa2-ns {
    padding: .5rem;
  }

  .pa3-ns {
    padding: 1rem;
  }

  .pa4-ns {
    padding: 2rem;
  }

  .pa5-ns {
    padding: 4rem;
  }

  .pa6-ns {
    padding: 8rem;
  }

  .pa7-ns {
    padding: 16rem;
  }

  .pl0-ns {
    padding-left: 0;
  }

  .pl1-ns {
    padding-left: .25rem;
  }

  .pl2-ns {
    padding-left: .5rem;
  }

  .pl3-ns {
    padding-left: 1rem;
  }

  .pl4-ns {
    padding-left: 2rem;
  }

  .pl5-ns {
    padding-left: 4rem;
  }

  .pl6-ns {
    padding-left: 8rem;
  }

  .pl7-ns {
    padding-left: 16rem;
  }

  .pr0-ns {
    padding-right: 0;
  }

  .pr1-ns {
    padding-right: .25rem;
  }

  .pr2-ns {
    padding-right: .5rem;
  }

  .pr3-ns {
    padding-right: 1rem;
  }

  .pr4-ns {
    padding-right: 2rem;
  }

  .pr5-ns {
    padding-right: 4rem;
  }

  .pr6-ns {
    padding-right: 8rem;
  }

  .pr7-ns {
    padding-right: 16rem;
  }

  .pb0-ns {
    padding-bottom: 0;
  }

  .pb1-ns {
    padding-bottom: .25rem;
  }

  .pb2-ns {
    padding-bottom: .5rem;
  }

  .pb3-ns {
    padding-bottom: 1rem;
  }

  .pb4-ns {
    padding-bottom: 2rem;
  }

  .pb5-ns {
    padding-bottom: 4rem;
  }

  .pb6-ns {
    padding-bottom: 8rem;
  }

  .pb7-ns {
    padding-bottom: 16rem;
  }

  .pt0-ns {
    padding-top: 0;
  }

  .pt1-ns {
    padding-top: .25rem;
  }

  .pt2-ns {
    padding-top: .5rem;
  }

  .pt3-ns {
    padding-top: 1rem;
  }

  .pt4-ns {
    padding-top: 2rem;
  }

  .pt5-ns {
    padding-top: 4rem;
  }

  .pt6-ns {
    padding-top: 8rem;
  }

  .pt7-ns {
    padding-top: 16rem;
  }

  .pv0-ns {
    padding-top: 0;
    padding-bottom: 0;
  }

  .pv1-ns {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .pv2-ns {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .pv3-ns {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .pv4-ns {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .pv5-ns {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .pv6-ns {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .pv7-ns {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .ph0-ns {
    padding-left: 0;
    padding-right: 0;
  }

  .ph1-ns {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .ph2-ns {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .ph3-ns {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .ph4-ns {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .ph5-ns {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .ph6-ns {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .ph7-ns {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .ma0-ns {
    margin: 0;
  }

  .ma1-ns {
    margin: .25rem;
  }

  .ma2-ns {
    margin: .5rem;
  }

  .ma3-ns {
    margin: 1rem;
  }

  .ma4-ns {
    margin: 2rem;
  }

  .ma5-ns {
    margin: 4rem;
  }

  .ma6-ns {
    margin: 8rem;
  }

  .ma7-ns {
    margin: 16rem;
  }

  .ml0-ns {
    margin-left: 0;
  }

  .ml1-ns {
    margin-left: .25rem;
  }

  .ml2-ns {
    margin-left: .5rem;
  }

  .ml3-ns {
    margin-left: 1rem;
  }

  .ml4-ns {
    margin-left: 2rem;
  }

  .ml5-ns {
    margin-left: 4rem;
  }

  .ml6-ns {
    margin-left: 8rem;
  }

  .ml7-ns {
    margin-left: 16rem;
  }

  .mr0-ns {
    margin-right: 0;
  }

  .mr1-ns {
    margin-right: .25rem;
  }

  .mr2-ns {
    margin-right: .5rem;
  }

  .mr3-ns {
    margin-right: 1rem;
  }

  .mr4-ns {
    margin-right: 2rem;
  }

  .mr5-ns {
    margin-right: 4rem;
  }

  .mr6-ns {
    margin-right: 8rem;
  }

  .mr7-ns {
    margin-right: 16rem;
  }

  .mb0-ns {
    margin-bottom: 0;
  }

  .mb1-ns {
    margin-bottom: .25rem;
  }

  .mb2-ns {
    margin-bottom: .5rem;
  }

  .mb3-ns {
    margin-bottom: 1rem;
  }

  .mb4-ns {
    margin-bottom: 2rem;
  }

  .mb5-ns {
    margin-bottom: 4rem;
  }

  .mb6-ns {
    margin-bottom: 8rem;
  }

  .mb7-ns {
    margin-bottom: 16rem;
  }

  .mt0-ns {
    margin-top: 0;
  }

  .mt1-ns {
    margin-top: .25rem;
  }

  .mt2-ns {
    margin-top: .5rem;
  }

  .mt3-ns {
    margin-top: 1rem;
  }

  .mt4-ns {
    margin-top: 2rem;
  }

  .mt5-ns {
    margin-top: 4rem;
  }

  .mt6-ns {
    margin-top: 8rem;
  }

  .mt7-ns {
    margin-top: 16rem;
  }

  .mv0-ns {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mv1-ns {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .mv2-ns {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .mv3-ns {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .mv4-ns {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .mv5-ns {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .mv6-ns {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .mv7-ns {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .mh0-ns {
    margin-left: 0;
    margin-right: 0;
  }

  .mh1-ns {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .mh2-ns {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .mh3-ns {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .mh4-ns {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .mh5-ns {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .mh6-ns {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .mh7-ns {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .strike-ns {
    text-decoration: line-through;
  }

  .underline-ns {
    text-decoration: underline;
  }

  .no-underline-ns {
    text-decoration: none;
  }

  .tl-ns {
    text-align: left;
  }

  .tr-ns {
    text-align: right;
  }

  .tc-ns {
    text-align: center;
  }

  .ttc-ns {
    text-transform: capitalize;
  }

  .ttl-ns {
    text-transform: lowercase;
  }

  .ttu-ns {
    text-transform: uppercase;
  }

  .ttn-ns {
    text-transform: none;
  }

  .f-6-ns, .f-headline-ns {
    font-size: 6rem;
  }

  .f-5-ns, .f-subheadline-ns {
    font-size: 5rem;
  }

  .f1-ns {
    font-size: 3rem;
  }

  .f2-ns {
    font-size: 2.25rem;
  }

  .f3-ns {
    font-size: 1.5rem;
  }

  .f4-ns {
    font-size: 1.25rem;
  }

  .f5-ns {
    font-size: 1rem;
  }

  .f6-ns {
    font-size: .875rem;
  }

  .measure-ns {
    max-width: 30em;
  }

  .measure-wide-ns {
    max-width: 34em;
  }

  .measure-narrow-ns {
    max-width: 20em;
  }

  .indent-ns {
    text-indent: 1em;
    margin-top: 0;
    margin-bottom: 0;
  }

  .small-caps-ns {
    font-variant: small-caps;
  }

  .truncate-ns {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .clip-ns {
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
  }

  .ws-normal-ns {
    white-space: normal;
  }

  .nowrap-ns {
    white-space: nowrap;
  }

  .pre-ns {
    white-space: pre;
  }

  .v-base-ns {
    vertical-align: baseline;
  }

  .v-mid-ns {
    vertical-align: middle;
  }

  .v-top-ns {
    vertical-align: top;
  }

  .v-btm-ns {
    vertical-align: bottom;
  }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
  .cover-m {
    background-size: cover;
  }

  .contain-m {
    background-size: contain;
  }

  .ba-m {
    border-style: solid;
    border-width: 1px;
  }

  .bt-m {
    border-top-style: solid;
    border-top-width: 1px;
  }

  .br-m {
    border-right-style: solid;
    border-right-width: 1px;
  }

  .bb-m {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

  .bl-m {
    border-left-style: solid;
    border-left-width: 1px;
  }

  .bn-m {
    border-style: none;
    border-width: 0;
  }

  .br0-m {
    border-radius: 0;
  }

  .br1-m {
    border-radius: .125rem;
  }

  .br2-m {
    border-radius: .25rem;
  }

  .br3-m {
    border-radius: .5rem;
  }

  .br4-m {
    border-radius: 1rem;
  }

  .br-100-m {
    border-radius: 100%;
  }

  .br--bottom-m {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .br--top-m {
    border-bottom-right-radius: 0;
  }

  .br--right-m, .br--top-m {
    border-bottom-left-radius: 0;
  }

  .br--right-m {
    border-top-left-radius: 0;
  }

  .br--left-m {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .b--dotted-m {
    border-style: dotted;
  }

  .b--dashed-m {
    border-style: dashed;
  }

  .b--solid-m {
    border-style: solid;
  }

  .b--none-m {
    border-style: none;
  }

  .bw0-m {
    border-width: 0;
  }

  .bw1-m {
    border-width: .125rem;
  }

  .bw2-m {
    border-width: .25rem;
  }

  .bw3-m {
    border-width: .5rem;
  }

  .bw4-m {
    border-width: 1rem;
  }

  .bw5-m {
    border-width: 2rem;
  }

  .bt-0-m {
    border-top-width: 0;
  }

  .br-0-m {
    border-right-width: 0;
  }

  .bb-0-m {
    border-bottom-width: 0;
  }

  .bl-0-m {
    border-left-width: 0;
  }

  .shadow-1-m {
    box-shadow: 0 0 4px 2px #0003;
  }

  .shadow-2-m {
    box-shadow: 0 0 8px 2px #0003;
  }

  .shadow-3-m {
    box-shadow: 2px 2px 4px 2px #0003;
  }

  .shadow-4-m {
    box-shadow: 2px 2px 8px #0003;
  }

  .shadow-5-m {
    box-shadow: 4px 4px 8px #0003;
  }

  .top-0-m {
    top: 0;
  }

  .left-0-m {
    left: 0;
  }

  .right-0-m {
    right: 0;
  }

  .bottom-0-m {
    bottom: 0;
  }

  .top-1-m {
    top: 1rem;
  }

  .left-1-m {
    left: 1rem;
  }

  .right-1-m {
    right: 1rem;
  }

  .bottom-1-m {
    bottom: 1rem;
  }

  .top-2-m {
    top: 2rem;
  }

  .left-2-m {
    left: 2rem;
  }

  .right-2-m {
    right: 2rem;
  }

  .bottom-2-m {
    bottom: 2rem;
  }

  .top--1-m {
    top: -1rem;
  }

  .right--1-m {
    right: -1rem;
  }

  .bottom--1-m {
    bottom: -1rem;
  }

  .left--1-m {
    left: -1rem;
  }

  .top--2-m {
    top: -2rem;
  }

  .right--2-m {
    right: -2rem;
  }

  .bottom--2-m {
    bottom: -2rem;
  }

  .left--2-m {
    left: -2rem;
  }

  .absolute--fill-m {
    inset: 0;
  }

  .cl-m {
    clear: left;
  }

  .cr-m {
    clear: right;
  }

  .cb-m {
    clear: both;
  }

  .cn-m {
    clear: none;
  }

  .dn-m {
    display: none;
  }

  .di-m {
    display: inline;
  }

  .db-m {
    display: block;
  }

  .dib-m {
    display: inline-block;
  }

  .dit-m {
    display: inline-table;
  }

  .dt-m {
    display: table;
  }

  .dtc-m {
    display: table-cell;
  }

  .dt-row-m {
    display: table-row;
  }

  .dt-row-group-m {
    display: table-row-group;
  }

  .dt-column-m {
    display: table-column;
  }

  .dt-column-group-m {
    display: table-column-group;
  }

  .dt--fixed-m {
    table-layout: fixed;
    width: 100%;
  }

  .flex-m {
    display: flex;
  }

  .inline-flex-m {
    display: inline-flex;
  }

  .flex-auto-m {
    min-width: 0;
    min-height: 0;
    flex: auto;
  }

  .flex-none-m {
    flex: none;
  }

  .flex-column-m {
    flex-direction: column;
  }

  .flex-row-m {
    flex-direction: row;
  }

  .flex-wrap-m {
    flex-wrap: wrap;
  }

  .items-start-m {
    align-items: flex-start;
  }

  .items-end-m {
    align-items: flex-end;
  }

  .items-center-m {
    align-items: center;
  }

  .items-baseline-m {
    align-items: baseline;
  }

  .items-stretch-m {
    align-items: stretch;
  }

  .self-start-m {
    align-self: flex-start;
  }

  .self-end-m {
    align-self: flex-end;
  }

  .self-center-m {
    align-self: center;
  }

  .self-baseline-m {
    align-self: baseline;
  }

  .self-stretch-m {
    align-self: stretch;
  }

  .justify-start-m {
    justify-content: flex-start;
  }

  .justify-end-m {
    justify-content: flex-end;
  }

  .justify-center-m {
    justify-content: center;
  }

  .justify-between-m {
    justify-content: space-between;
  }

  .justify-around-m {
    justify-content: space-around;
  }

  .content-start-m {
    align-content: flex-start;
  }

  .content-end-m {
    align-content: flex-end;
  }

  .content-center-m {
    align-content: center;
  }

  .content-between-m {
    align-content: space-between;
  }

  .content-around-m {
    align-content: space-around;
  }

  .content-stretch-m {
    align-content: stretch;
  }

  .order-0-m {
    order: 0;
  }

  .order-1-m {
    order: 1;
  }

  .order-2-m {
    order: 2;
  }

  .order-3-m {
    order: 3;
  }

  .order-4-m {
    order: 4;
  }

  .order-5-m {
    order: 5;
  }

  .order-6-m {
    order: 6;
  }

  .order-7-m {
    order: 7;
  }

  .order-8-m {
    order: 8;
  }

  .order-last-m {
    order: 99999;
  }

  .fl-m {
    float: left;
  }

  .fl-m, .fr-m {
    display: inline;
  }

  .fr-m {
    float: right;
  }

  .fn-m {
    float: none;
  }

  .i-m {
    font-style: italic;
  }

  .fs-normal-m {
    font-style: normal;
  }

  .normal-m {
    font-weight: 400;
  }

  .b-m {
    font-weight: 700;
  }

  .fw1-m {
    font-weight: 100;
  }

  .fw2-m {
    font-weight: 200;
  }

  .fw3-m {
    font-weight: 300;
  }

  .fw4-m {
    font-weight: 400;
  }

  .fw5-m {
    font-weight: 500;
  }

  .fw6-m {
    font-weight: 600;
  }

  .fw7-m {
    font-weight: 700;
  }

  .fw8-m {
    font-weight: 800;
  }

  .fw9-m {
    font-weight: 900;
  }

  .h1-m {
    height: 1rem;
  }

  .h2-m {
    height: 2rem;
  }

  .h3-m {
    height: 4rem;
  }

  .h4-m {
    height: 8rem;
  }

  .h5-m {
    height: 16rem;
  }

  .h-25-m {
    height: 25%;
  }

  .h-50-m {
    height: 50%;
  }

  .h-75-m {
    height: 75%;
  }

  .h-100-m {
    height: 100%;
  }

  .h-auto-m {
    height: auto;
  }

  .h-inherit-m {
    height: inherit;
  }

  .tracked-m {
    letter-spacing: .1em;
  }

  .tracked-tight-m {
    letter-spacing: -.05em;
  }

  .tracked-mega-m {
    letter-spacing: .25em;
  }

  .lh-solid-m {
    line-height: 1;
  }

  .lh-title-m {
    line-height: 1.3;
  }

  .lh-copy-m {
    line-height: 1.6;
  }

  .mw-100-m {
    max-width: 100%;
  }

  .mw1-m {
    max-width: 1rem;
  }

  .mw2-m {
    max-width: 2rem;
  }

  .mw3-m {
    max-width: 4rem;
  }

  .mw4-m {
    max-width: 8rem;
  }

  .mw5-m {
    max-width: 16rem;
  }

  .mw6-m {
    max-width: 32rem;
  }

  .mw7-m {
    max-width: 48rem;
  }

  .mw8-m {
    max-width: 64rem;
  }

  .mw9-m {
    max-width: 96rem;
  }

  .mw-none-m {
    max-width: none;
  }

  .w1-m {
    width: 1rem;
  }

  .w2-m {
    width: 2rem;
  }

  .w3-m {
    width: 4rem;
  }

  .w4-m {
    width: 8rem;
  }

  .w5-m {
    width: 16rem;
  }

  .w-10-m {
    width: 10%;
  }

  .w-20-m {
    width: 20%;
  }

  .w-25-m {
    width: 25%;
  }

  .w-33-m {
    width: 33%;
  }

  .w-34-m {
    width: 34%;
  }

  .w-40-m {
    width: 40%;
  }

  .w-50-m {
    width: 50%;
  }

  .w-60-m {
    width: 60%;
  }

  .w-75-m {
    width: 75%;
  }

  .w-80-m {
    width: 80%;
  }

  .w-100-m {
    width: 100%;
  }

  .w-auto-m {
    width: auto;
  }

  .overflow-visible-m {
    overflow: visible;
  }

  .overflow-hidden-m {
    overflow: hidden;
  }

  .overflow-scroll-m {
    overflow: scroll;
  }

  .overflow-auto-m {
    overflow: auto;
  }

  .overflow-x-visible-m {
    overflow-x: visible;
  }

  .overflow-x-hidden-m {
    overflow-x: hidden;
  }

  .overflow-x-scroll-m {
    overflow-x: scroll;
  }

  .overflow-x-auto-m {
    overflow-x: auto;
  }

  .overflow-y-visible-m {
    overflow-y: visible;
  }

  .overflow-y-hidden-m {
    overflow-y: hidden;
  }

  .overflow-y-scroll-m {
    overflow-y: scroll;
  }

  .overflow-y-auto-m {
    overflow-y: auto;
  }

  .static-m {
    position: static;
  }

  .relative-m {
    position: relative;
  }

  .absolute-m {
    position: absolute;
  }

  .fixed-m {
    position: fixed;
  }

  .pa0-m {
    padding: 0;
  }

  .pa1-m {
    padding: .25rem;
  }

  .pa2-m {
    padding: .5rem;
  }

  .pa3-m {
    padding: 1rem;
  }

  .pa4-m {
    padding: 2rem;
  }

  .pa5-m {
    padding: 4rem;
  }

  .pa6-m {
    padding: 8rem;
  }

  .pa7-m {
    padding: 16rem;
  }

  .pl0-m {
    padding-left: 0;
  }

  .pl1-m {
    padding-left: .25rem;
  }

  .pl2-m {
    padding-left: .5rem;
  }

  .pl3-m {
    padding-left: 1rem;
  }

  .pl4-m {
    padding-left: 2rem;
  }

  .pl5-m {
    padding-left: 4rem;
  }

  .pl6-m {
    padding-left: 8rem;
  }

  .pl7-m {
    padding-left: 16rem;
  }

  .pr0-m {
    padding-right: 0;
  }

  .pr1-m {
    padding-right: .25rem;
  }

  .pr2-m {
    padding-right: .5rem;
  }

  .pr3-m {
    padding-right: 1rem;
  }

  .pr4-m {
    padding-right: 2rem;
  }

  .pr5-m {
    padding-right: 4rem;
  }

  .pr6-m {
    padding-right: 8rem;
  }

  .pr7-m {
    padding-right: 16rem;
  }

  .pb0-m {
    padding-bottom: 0;
  }

  .pb1-m {
    padding-bottom: .25rem;
  }

  .pb2-m {
    padding-bottom: .5rem;
  }

  .pb3-m {
    padding-bottom: 1rem;
  }

  .pb4-m {
    padding-bottom: 2rem;
  }

  .pb5-m {
    padding-bottom: 4rem;
  }

  .pb6-m {
    padding-bottom: 8rem;
  }

  .pb7-m {
    padding-bottom: 16rem;
  }

  .pt0-m {
    padding-top: 0;
  }

  .pt1-m {
    padding-top: .25rem;
  }

  .pt2-m {
    padding-top: .5rem;
  }

  .pt3-m {
    padding-top: 1rem;
  }

  .pt4-m {
    padding-top: 2rem;
  }

  .pt5-m {
    padding-top: 4rem;
  }

  .pt6-m {
    padding-top: 8rem;
  }

  .pt7-m {
    padding-top: 16rem;
  }

  .pv0-m {
    padding-top: 0;
    padding-bottom: 0;
  }

  .pv1-m {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .pv2-m {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .pv3-m {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .pv4-m {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .pv5-m {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .pv6-m {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .pv7-m {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .ph0-m {
    padding-left: 0;
    padding-right: 0;
  }

  .ph1-m {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .ph2-m {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .ph3-m {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .ph4-m {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .ph5-m {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .ph6-m {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .ph7-m {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .ma0-m {
    margin: 0;
  }

  .ma1-m {
    margin: .25rem;
  }

  .ma2-m {
    margin: .5rem;
  }

  .ma3-m {
    margin: 1rem;
  }

  .ma4-m {
    margin: 2rem;
  }

  .ma5-m {
    margin: 4rem;
  }

  .ma6-m {
    margin: 8rem;
  }

  .ma7-m {
    margin: 16rem;
  }

  .ml0-m {
    margin-left: 0;
  }

  .ml1-m {
    margin-left: .25rem;
  }

  .ml2-m {
    margin-left: .5rem;
  }

  .ml3-m {
    margin-left: 1rem;
  }

  .ml4-m {
    margin-left: 2rem;
  }

  .ml5-m {
    margin-left: 4rem;
  }

  .ml6-m {
    margin-left: 8rem;
  }

  .ml7-m {
    margin-left: 16rem;
  }

  .mr0-m {
    margin-right: 0;
  }

  .mr1-m {
    margin-right: .25rem;
  }

  .mr2-m {
    margin-right: .5rem;
  }

  .mr3-m {
    margin-right: 1rem;
  }

  .mr4-m {
    margin-right: 2rem;
  }

  .mr5-m {
    margin-right: 4rem;
  }

  .mr6-m {
    margin-right: 8rem;
  }

  .mr7-m {
    margin-right: 16rem;
  }

  .mb0-m {
    margin-bottom: 0;
  }

  .mb1-m {
    margin-bottom: .25rem;
  }

  .mb2-m {
    margin-bottom: .5rem;
  }

  .mb3-m {
    margin-bottom: 1rem;
  }

  .mb4-m {
    margin-bottom: 2rem;
  }

  .mb5-m {
    margin-bottom: 4rem;
  }

  .mb6-m {
    margin-bottom: 8rem;
  }

  .mb7-m {
    margin-bottom: 16rem;
  }

  .mt0-m {
    margin-top: 0;
  }

  .mt1-m {
    margin-top: .25rem;
  }

  .mt2-m {
    margin-top: .5rem;
  }

  .mt3-m {
    margin-top: 1rem;
  }

  .mt4-m {
    margin-top: 2rem;
  }

  .mt5-m {
    margin-top: 4rem;
  }

  .mt6-m {
    margin-top: 8rem;
  }

  .mt7-m {
    margin-top: 16rem;
  }

  .mv0-m {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mv1-m {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .mv2-m {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .mv3-m {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .mv4-m {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .mv5-m {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .mv6-m {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .mv7-m {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .mh0-m {
    margin-left: 0;
    margin-right: 0;
  }

  .mh1-m {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .mh2-m {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .mh3-m {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .mh4-m {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .mh5-m {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .mh6-m {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .mh7-m {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .strike-m {
    text-decoration: line-through;
  }

  .underline-m {
    text-decoration: underline;
  }

  .no-underline-m {
    text-decoration: none;
  }

  .tl-m {
    text-align: left;
  }

  .tr-m {
    text-align: right;
  }

  .tc-m {
    text-align: center;
  }

  .ttc-m {
    text-transform: capitalize;
  }

  .ttl-m {
    text-transform: lowercase;
  }

  .ttu-m {
    text-transform: uppercase;
  }

  .ttn-m {
    text-transform: none;
  }

  .f-6-m, .f-headline-m {
    font-size: 6rem;
  }

  .f-5-m, .f-subheadline-m {
    font-size: 5rem;
  }

  .f1-m {
    font-size: 3rem;
  }

  .f2-m {
    font-size: 2.25rem;
  }

  .f3-m {
    font-size: 1.5rem;
  }

  .f4-m {
    font-size: 1.25rem;
  }

  .f5-m {
    font-size: 1rem;
  }

  .f6-m {
    font-size: .875rem;
  }

  .measure-m {
    max-width: 30em;
  }

  .measure-wide-m {
    max-width: 34em;
  }

  .measure-narrow-m {
    max-width: 20em;
  }

  .indent-m {
    text-indent: 1em;
    margin-top: 0;
    margin-bottom: 0;
  }

  .small-caps-m {
    font-variant: small-caps;
  }

  .truncate-m {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .clip-m {
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
  }

  .ws-normal-m {
    white-space: normal;
  }

  .nowrap-m {
    white-space: nowrap;
  }

  .pre-m {
    white-space: pre;
  }

  .v-base-m {
    vertical-align: baseline;
  }

  .v-mid-m {
    vertical-align: middle;
  }

  .v-top-m {
    vertical-align: top;
  }

  .v-btm-m {
    vertical-align: bottom;
  }
}

@media screen and (min-width: 60em) {
  .cover-l {
    background-size: cover;
  }

  .contain-l {
    background-size: contain;
  }

  .ba-l {
    border-style: solid;
    border-width: 1px;
  }

  .bt-l {
    border-top-style: solid;
    border-top-width: 1px;
  }

  .br-l {
    border-right-style: solid;
    border-right-width: 1px;
  }

  .bb-l {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

  .bl-l {
    border-left-style: solid;
    border-left-width: 1px;
  }

  .bn-l {
    border-style: none;
    border-width: 0;
  }

  .br0-l {
    border-radius: 0;
  }

  .br1-l {
    border-radius: .125rem;
  }

  .br2-l {
    border-radius: .25rem;
  }

  .br3-l {
    border-radius: .5rem;
  }

  .br4-l {
    border-radius: 1rem;
  }

  .br-100-l {
    border-radius: 100%;
  }

  .br--bottom-l {
    border-radius-top-left: 0;
    border-radius-top-right: 0;
  }

  .br--top-l {
    border-bottom-right-radius: 0;
  }

  .br--right-l, .br--top-l {
    border-bottom-left-radius: 0;
  }

  .br--right-l {
    border-top-left-radius: 0;
  }

  .br--left-l {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .b--dotted-l {
    border-style: dotted;
  }

  .b--dashed-l {
    border-style: dashed;
  }

  .b--solid-l {
    border-style: solid;
  }

  .b--none-l {
    border-style: none;
  }

  .bw0-l {
    border-width: 0;
  }

  .bw1-l {
    border-width: .125rem;
  }

  .bw2-l {
    border-width: .25rem;
  }

  .bw3-l {
    border-width: .5rem;
  }

  .bw4-l {
    border-width: 1rem;
  }

  .bw5-l {
    border-width: 2rem;
  }

  .bt-0-l {
    border-top-width: 0;
  }

  .br-0-l {
    border-right-width: 0;
  }

  .bb-0-l {
    border-bottom-width: 0;
  }

  .bl-0-l {
    border-left-width: 0;
  }

  .shadow-1-l {
    box-shadow: 0 0 4px 2px #0003;
  }

  .shadow-2-l {
    box-shadow: 0 0 8px 2px #0003;
  }

  .shadow-3-l {
    box-shadow: 2px 2px 4px 2px #0003;
  }

  .shadow-4-l {
    box-shadow: 2px 2px 8px #0003;
  }

  .shadow-5-l {
    box-shadow: 4px 4px 8px #0003;
  }

  .top-0-l {
    top: 0;
  }

  .left-0-l {
    left: 0;
  }

  .right-0-l {
    right: 0;
  }

  .bottom-0-l {
    bottom: 0;
  }

  .top-1-l {
    top: 1rem;
  }

  .left-1-l {
    left: 1rem;
  }

  .right-1-l {
    right: 1rem;
  }

  .bottom-1-l {
    bottom: 1rem;
  }

  .top-2-l {
    top: 2rem;
  }

  .left-2-l {
    left: 2rem;
  }

  .right-2-l {
    right: 2rem;
  }

  .bottom-2-l {
    bottom: 2rem;
  }

  .top--1-l {
    top: -1rem;
  }

  .right--1-l {
    right: -1rem;
  }

  .bottom--1-l {
    bottom: -1rem;
  }

  .left--1-l {
    left: -1rem;
  }

  .top--2-l {
    top: -2rem;
  }

  .right--2-l {
    right: -2rem;
  }

  .bottom--2-l {
    bottom: -2rem;
  }

  .left--2-l {
    left: -2rem;
  }

  .absolute--fill-l {
    inset: 0;
  }

  .cl-l {
    clear: left;
  }

  .cr-l {
    clear: right;
  }

  .cb-l {
    clear: both;
  }

  .cn-l {
    clear: none;
  }

  .dn-l {
    display: none;
  }

  .di-l {
    display: inline;
  }

  .db-l {
    display: block;
  }

  .dib-l {
    display: inline-block;
  }

  .dit-l {
    display: inline-table;
  }

  .dt-l {
    display: table;
  }

  .dtc-l {
    display: table-cell;
  }

  .dt-row-l {
    display: table-row;
  }

  .dt-row-group-l {
    display: table-row-group;
  }

  .dt-column-l {
    display: table-column;
  }

  .dt-column-group-l {
    display: table-column-group;
  }

  .dt--fixed-l {
    table-layout: fixed;
    width: 100%;
  }

  .flex-l {
    display: flex;
  }

  .inline-flex-l {
    display: inline-flex;
  }

  .flex-auto-l {
    min-width: 0;
    min-height: 0;
    flex: auto;
  }

  .flex-none-l {
    flex: none;
  }

  .flex-column-l {
    flex-direction: column;
  }

  .flex-row-l {
    flex-direction: row;
  }

  .flex-wrap-l {
    flex-wrap: wrap;
  }

  .items-start-l {
    align-items: flex-start;
  }

  .items-end-l {
    align-items: flex-end;
  }

  .items-center-l {
    align-items: center;
  }

  .items-baseline-l {
    align-items: baseline;
  }

  .items-stretch-l {
    align-items: stretch;
  }

  .self-start-l {
    align-self: flex-start;
  }

  .self-end-l {
    align-self: flex-end;
  }

  .self-center-l {
    align-self: center;
  }

  .self-baseline-l {
    align-self: baseline;
  }

  .self-stretch-l {
    align-self: stretch;
  }

  .justify-start-l {
    justify-content: flex-start;
  }

  .justify-end-l {
    justify-content: flex-end;
  }

  .justify-center-l {
    justify-content: center;
  }

  .justify-between-l {
    justify-content: space-between;
  }

  .justify-around-l {
    justify-content: space-around;
  }

  .content-start-l {
    align-content: flex-start;
  }

  .content-end-l {
    align-content: flex-end;
  }

  .content-center-l {
    align-content: center;
  }

  .content-between-l {
    align-content: space-between;
  }

  .content-around-l {
    align-content: space-around;
  }

  .content-stretch-l {
    align-content: stretch;
  }

  .order-0-l {
    order: 0;
  }

  .order-1-l {
    order: 1;
  }

  .order-2-l {
    order: 2;
  }

  .order-3-l {
    order: 3;
  }

  .order-4-l {
    order: 4;
  }

  .order-5-l {
    order: 5;
  }

  .order-6-l {
    order: 6;
  }

  .order-7-l {
    order: 7;
  }

  .order-8-l {
    order: 8;
  }

  .order-last-l {
    order: 99999;
  }

  .fl-l {
    float: left;
  }

  .fl-l, .fr-l {
    display: inline;
  }

  .fr-l {
    float: right;
  }

  .fn-l {
    float: none;
  }

  .i-l {
    font-style: italic;
  }

  .fs-normal-l {
    font-style: normal;
  }

  .normal-l {
    font-weight: 400;
  }

  .b-l {
    font-weight: 700;
  }

  .fw1-l {
    font-weight: 100;
  }

  .fw2-l {
    font-weight: 200;
  }

  .fw3-l {
    font-weight: 300;
  }

  .fw4-l {
    font-weight: 400;
  }

  .fw5-l {
    font-weight: 500;
  }

  .fw6-l {
    font-weight: 600;
  }

  .fw7-l {
    font-weight: 700;
  }

  .fw8-l {
    font-weight: 800;
  }

  .fw9-l {
    font-weight: 900;
  }

  .h1-l {
    height: 1rem;
  }

  .h2-l {
    height: 2rem;
  }

  .h3-l {
    height: 4rem;
  }

  .h4-l {
    height: 8rem;
  }

  .h5-l {
    height: 16rem;
  }

  .h-25-l {
    height: 25%;
  }

  .h-50-l {
    height: 50%;
  }

  .h-75-l {
    height: 75%;
  }

  .h-100-l {
    height: 100%;
  }

  .h-auto-l {
    height: auto;
  }

  .h-inherit-l {
    height: inherit;
  }

  .tracked-l {
    letter-spacing: .1em;
  }

  .tracked-tight-l {
    letter-spacing: -.05em;
  }

  .tracked-mega-l {
    letter-spacing: .25em;
  }

  .lh-solid-l {
    line-height: 1;
  }

  .lh-title-l {
    line-height: 1.3;
  }

  .lh-copy-l {
    line-height: 1.6;
  }

  .mw-100-l {
    max-width: 100%;
  }

  .mw1-l {
    max-width: 1rem;
  }

  .mw2-l {
    max-width: 2rem;
  }

  .mw3-l {
    max-width: 4rem;
  }

  .mw4-l {
    max-width: 8rem;
  }

  .mw5-l {
    max-width: 16rem;
  }

  .mw6-l {
    max-width: 32rem;
  }

  .mw7-l {
    max-width: 48rem;
  }

  .mw8-l {
    max-width: 64rem;
  }

  .mw9-l {
    max-width: 96rem;
  }

  .mw-none-l {
    max-width: none;
  }

  .w1-l {
    width: 1rem;
  }

  .w2-l {
    width: 2rem;
  }

  .w3-l {
    width: 4rem;
  }

  .w4-l {
    width: 8rem;
  }

  .w5-l {
    width: 16rem;
  }

  .w-10-l {
    width: 10%;
  }

  .w-20-l {
    width: 20%;
  }

  .w-25-l {
    width: 25%;
  }

  .w-33-l {
    width: 33%;
  }

  .w-34-l {
    width: 34%;
  }

  .w-40-l {
    width: 40%;
  }

  .w-50-l {
    width: 50%;
  }

  .w-60-l {
    width: 60%;
  }

  .w-75-l {
    width: 75%;
  }

  .w-80-l {
    width: 80%;
  }

  .w-100-l {
    width: 100%;
  }

  .w-auto-l {
    width: auto;
  }

  .overflow-visible-l {
    overflow: visible;
  }

  .overflow-hidden-l {
    overflow: hidden;
  }

  .overflow-scroll-l {
    overflow: scroll;
  }

  .overflow-auto-l {
    overflow: auto;
  }

  .overflow-x-visible-l {
    overflow-x: visible;
  }

  .overflow-x-hidden-l {
    overflow-x: hidden;
  }

  .overflow-x-scroll-l {
    overflow-x: scroll;
  }

  .overflow-x-auto-l {
    overflow-x: auto;
  }

  .overflow-y-visible-l {
    overflow-y: visible;
  }

  .overflow-y-hidden-l {
    overflow-y: hidden;
  }

  .overflow-y-scroll-l {
    overflow-y: scroll;
  }

  .overflow-y-auto-l {
    overflow-y: auto;
  }

  .static-l {
    position: static;
  }

  .relative-l {
    position: relative;
  }

  .absolute-l {
    position: absolute;
  }

  .fixed-l {
    position: fixed;
  }

  .pa0-l {
    padding: 0;
  }

  .pa1-l {
    padding: .25rem;
  }

  .pa2-l {
    padding: .5rem;
  }

  .pa3-l {
    padding: 1rem;
  }

  .pa4-l {
    padding: 2rem;
  }

  .pa5-l {
    padding: 4rem;
  }

  .pa6-l {
    padding: 8rem;
  }

  .pa7-l {
    padding: 16rem;
  }

  .pl0-l {
    padding-left: 0;
  }

  .pl1-l {
    padding-left: .25rem;
  }

  .pl2-l {
    padding-left: .5rem;
  }

  .pl3-l {
    padding-left: 1rem;
  }

  .pl4-l {
    padding-left: 2rem;
  }

  .pl5-l {
    padding-left: 4rem;
  }

  .pl6-l {
    padding-left: 8rem;
  }

  .pl7-l {
    padding-left: 16rem;
  }

  .pr0-l {
    padding-right: 0;
  }

  .pr1-l {
    padding-right: .25rem;
  }

  .pr2-l {
    padding-right: .5rem;
  }

  .pr3-l {
    padding-right: 1rem;
  }

  .pr4-l {
    padding-right: 2rem;
  }

  .pr5-l {
    padding-right: 4rem;
  }

  .pr6-l {
    padding-right: 8rem;
  }

  .pr7-l {
    padding-right: 16rem;
  }

  .pb0-l {
    padding-bottom: 0;
  }

  .pb1-l {
    padding-bottom: .25rem;
  }

  .pb2-l {
    padding-bottom: .5rem;
  }

  .pb3-l {
    padding-bottom: 1rem;
  }

  .pb4-l {
    padding-bottom: 2rem;
  }

  .pb5-l {
    padding-bottom: 4rem;
  }

  .pb6-l {
    padding-bottom: 8rem;
  }

  .pb7-l {
    padding-bottom: 16rem;
  }

  .pt0-l {
    padding-top: 0;
  }

  .pt1-l {
    padding-top: .25rem;
  }

  .pt2-l {
    padding-top: .5rem;
  }

  .pt3-l {
    padding-top: 1rem;
  }

  .pt4-l {
    padding-top: 2rem;
  }

  .pt5-l {
    padding-top: 4rem;
  }

  .pt6-l {
    padding-top: 8rem;
  }

  .pt7-l {
    padding-top: 16rem;
  }

  .pv0-l {
    padding-top: 0;
    padding-bottom: 0;
  }

  .pv1-l {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .pv2-l {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .pv3-l {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .pv4-l {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .pv5-l {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .pv6-l {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .pv7-l {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .ph0-l {
    padding-left: 0;
    padding-right: 0;
  }

  .ph1-l {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .ph2-l {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .ph3-l {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .ph4-l {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .ph5-l {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .ph6-l {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .ph7-l {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .ma0-l {
    margin: 0;
  }

  .ma1-l {
    margin: .25rem;
  }

  .ma2-l {
    margin: .5rem;
  }

  .ma3-l {
    margin: 1rem;
  }

  .ma4-l {
    margin: 2rem;
  }

  .ma5-l {
    margin: 4rem;
  }

  .ma6-l {
    margin: 8rem;
  }

  .ma7-l {
    margin: 16rem;
  }

  .ml0-l {
    margin-left: 0;
  }

  .ml1-l {
    margin-left: .25rem;
  }

  .ml2-l {
    margin-left: .5rem;
  }

  .ml3-l {
    margin-left: 1rem;
  }

  .ml4-l {
    margin-left: 2rem;
  }

  .ml5-l {
    margin-left: 4rem;
  }

  .ml6-l {
    margin-left: 8rem;
  }

  .ml7-l {
    margin-left: 16rem;
  }

  .mr0-l {
    margin-right: 0;
  }

  .mr1-l {
    margin-right: .25rem;
  }

  .mr2-l {
    margin-right: .5rem;
  }

  .mr3-l {
    margin-right: 1rem;
  }

  .mr4-l {
    margin-right: 2rem;
  }

  .mr5-l {
    margin-right: 4rem;
  }

  .mr6-l {
    margin-right: 8rem;
  }

  .mr7-l {
    margin-right: 16rem;
  }

  .mb0-l {
    margin-bottom: 0;
  }

  .mb1-l {
    margin-bottom: .25rem;
  }

  .mb2-l {
    margin-bottom: .5rem;
  }

  .mb3-l {
    margin-bottom: 1rem;
  }

  .mb4-l {
    margin-bottom: 2rem;
  }

  .mb5-l {
    margin-bottom: 4rem;
  }

  .mb6-l {
    margin-bottom: 8rem;
  }

  .mb7-l {
    margin-bottom: 16rem;
  }

  .mt0-l {
    margin-top: 0;
  }

  .mt1-l {
    margin-top: .25rem;
  }

  .mt2-l {
    margin-top: .5rem;
  }

  .mt3-l {
    margin-top: 1rem;
  }

  .mt4-l {
    margin-top: 2rem;
  }

  .mt5-l {
    margin-top: 4rem;
  }

  .mt6-l {
    margin-top: 8rem;
  }

  .mt7-l {
    margin-top: 16rem;
  }

  .mv0-l {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mv1-l {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .mv2-l {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .mv3-l {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .mv4-l {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .mv5-l {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .mv6-l {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .mv7-l {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .mh0-l {
    margin-left: 0;
    margin-right: 0;
  }

  .mh1-l {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .mh2-l {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .mh3-l {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .mh4-l {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .mh5-l {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .mh6-l {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .mh7-l {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .strike-l {
    text-decoration: line-through;
  }

  .underline-l {
    text-decoration: underline;
  }

  .no-underline-l {
    text-decoration: none;
  }

  .tl-l {
    text-align: left;
  }

  .tr-l {
    text-align: right;
  }

  .tc-l {
    text-align: center;
  }

  .ttc-l {
    text-transform: capitalize;
  }

  .ttl-l {
    text-transform: lowercase;
  }

  .ttu-l {
    text-transform: uppercase;
  }

  .ttn-l {
    text-transform: none;
  }

  .f-6-l, .f-headline-l {
    font-size: 6rem;
  }

  .f-5-l, .f-subheadline-l {
    font-size: 5rem;
  }

  .f1-l {
    font-size: 3rem;
  }

  .f2-l {
    font-size: 2.25rem;
  }

  .f3-l {
    font-size: 1.5rem;
  }

  .f4-l {
    font-size: 1.25rem;
  }

  .f5-l {
    font-size: 1rem;
  }

  .f6-l {
    font-size: .875rem;
  }

  .measure-l {
    max-width: 30em;
  }

  .measure-wide-l {
    max-width: 34em;
  }

  .measure-narrow-l {
    max-width: 20em;
  }

  .indent-l {
    text-indent: 1em;
    margin-top: 0;
    margin-bottom: 0;
  }

  .small-caps-l {
    font-variant: small-caps;
  }

  .truncate-l {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .clip-l {
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
  }

  .ws-normal-l {
    white-space: normal;
  }

  .nowrap-l {
    white-space: nowrap;
  }

  .pre-l {
    white-space: pre;
  }

  .v-base-l {
    vertical-align: baseline;
  }

  .v-mid-l {
    vertical-align: middle;
  }

  .v-top-l {
    vertical-align: top;
  }

  .v-btm-l {
    vertical-align: bottom;
  }
}

html, body {
  height: 100%;
}

body {
  color: var(--main-text-color);
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
}

.sans-serif {
  font-family: var(--sans-serif);
}

.serif {
  font-family: var(--serif);
}

.light-silver {
  color: var(--light-silver);
}

nav a {
  color: var(--main-text-color);
  text-decoration: none;
}

.project-footer a {
  color: var(--main-text-color);
  transition: var(--transition-values);
  text-decoration: none;
}

.project-footer a:hover {
  color: #bdb099;
  transition: var(--transition-values);
}

footer {
  background-color: #faf9f4;
}

footer p {
  color: var(--light-silver);
}

footer a {
  color: var(--main-text-color);
  text-decoration: none;
}

.list a {
  color: var(--main-text-color);
  border-bottom: .1vh solid #ff4200;
  text-decoration: none;
}

.intro-text {
  letter-spacing: -.01em;
}

.picfair {
  background: url("//res.cloudinary.com/dikdffjaw/image/upload/c_scale,q_auto,w_2048/v1472991816/Lt0DwxdqRKSQkX7439ey_Chaz_fisheye-11.jpg_u6i65t.jpg");
}

.bunchcut {
  background: url("//res.cloudinary.com/dikdffjaw/image/upload/c_scale,q_auto,w_2048/v1472993759/photo-1454165205744-3b78555e5572_jbnvqw.jpg");
}

.velvet {
  background: url("https://res.cloudinary.com/dikdffjaw/image/upload/c_scale,q_auto/v1472991902/velvet-box_kdkwf5.png");
}

.mt52 {
  margin-top: 3.25em;
}

.project {
  height: 125px;
  cursor: pointer;
  text-shadow: 0 2px 15px #000000b3;
  background-color: #d3d3d3;
  background-position: center;
  background-size: cover;
  margin-top: 35px;
  box-shadow: 0 20px 80px #00000026;
}

.projects a {
  text-decoration: none;
}

.projects > div {
  width: 100%;
}

.project, .overlay {
  border-radius: 4px;
}

.overlay {
  width: 100%;
  height: 100%;
  color: #fff;
  transition: var(--transition-values);
  will-change: opacity;
  background-color: #0000004d;
}

.overlay:hover {
  transition: var(--transition-values);
  background-color: #0000004d;
}

@media screen and (min-width: 30em) {
  .projects > div {
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .project, .project > .overlay {
    height: 30rem;
  }

  .overlay {
    background-color: #0000;
  }
}

/*# sourceMappingURL=index.fa73343a.css.map */
